// components/USDIStats.js
import React from 'react';
import Footer from './footer/Footer';
import Header from './header/Header';
import SmartContractTable from './usdi_tables/SmartContractTable';
import OverallUSDIPayout from './usdi_tables/OverallUsdiPayout';
import RawBlockchain from './usdi_tables/RawBlockchain';
import HolderInfoTable from './usdi_tables/USDIHoldersInfo';
import UserInfo from './usdi_tables/users';
import FilterExample from './usdi_tables/filterExample';

const USDIStats = () => {
  return (
    <div className="container mt-5">
      <div className="h d-flex justify-content-between align-items-center mb-3">
        <Header />
      </div>
      <div className="title-box d-flex justify-content-between align-items-center">
      </div>
      <h3>Smart Contract Table</h3>
      <SmartContractTable />
      <h3>Overall USDI Payout</h3>
      <OverallUSDIPayout />
      <h3>Raw Blockchain Data</h3>
      <RawBlockchain />
      <h3>USDI Holders Info</h3>
      <HolderInfoTable />
      <h3>User Info</h3>
      <UserInfo />
      <h3>Filter by Address Example</h3>
      <FilterExample />
      <Footer />
    </div>
  );
};

export default USDIStats;
