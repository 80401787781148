export const jsonToCSV = (json) => {
    const csvRows = [];
    const headers = Object.keys(json[0]);
  
    csvRows.push(headers.join(','));
  
    for (const row of json) {
      const values = headers.map(header => {
        const value = row[header];
        const escapedValue = ('' + value).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    }
  
    return csvRows.join('\n');
  };
  