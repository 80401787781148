import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './App.css';
import EnterImage from './Enter.svg';
import mobileImage from './Rectangle 309.png';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.logout();
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (auth.login(email, password)) {
      navigate('/home/track-records');
    } else {
      setError('Invalid email or invitation code');
    }
  };

  return (
    <div className="login-container">

      <div className="login-form">

        <img className="mobile-image" src={mobileImage} alt="Login" />

        <div className="green-header"><h2>Early Access</h2></div>

        <p>Enter your invitation code to gain early access</p>
        <form onSubmit={handleLogin}>
          <div className="input-form">
            <label>Email Address</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-form">
            <label>Invitation Code</label>
            <input
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit"><img className="enter" src={EnterImage} alt=''/></button>
        </form>
      </div>
      <div className="login-image">
        <img src="/images/login.png" alt="Login" />
      </div>
    </div>
  );
};

export default LoginPage;
